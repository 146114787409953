/**
 * Maps a domain to a country code. For example, "mnm.st" maps to "at" (Austria).
 */
export const domainToCountryCode = {
  "mnm.st": "at",
  "minimist.at": "at", // Austria
  "minimist.be": "be", // Belgium
  "minimist.bg": "bg", // Bulgaria
  "minimist.cy": "cy", // Cyprus
  "minimist.cz": "cz", // Czech Republic
  "minimist.de": "de", // Germany
  "minimist.dk": "dk", // Denmark
  "minimist.ee": "ee", // Estonia
  "minimist.es": "es", // Spain
  "minimist.fi": "fi", // Finland
  "minimist.fr": "fr", // France
  "minimist.gr": "gr", // Greece
  "minimist.hr": "hr", // Croatia
  "minimist.hu": "hu", // Hungary
  "minimist.ie": "ie", // Ireland
  "minimist.it": "it", // Italy
  "minimist.lt": "lt", // Lithuania
  "minimist.lu": "lu", // Luxembourg
  "minimist.lv": "lv", // Latvia
  "minimist.mt": "mt", // Malta
  "minimist.nl": "nl", // Netherlands
  "minimist.pl": "pl", // Poland
  "minimist.pt": "pt", // Portugal
  "minimist.ro": "ro", // Romania
  "getminimist.se": "se", // Sweden
  "minimist.si": "si", // Slovenia
  "minimist.sk": "sk", // Slovakia
} as const

/**
 * Maps a country code to a domain. For example, "at" maps to "minimist.at",
 * and "se" maps to "getminimist.se".
 */
export const countryCodeToDomain = Object.fromEntries(
  Object.entries(domainToCountryCode)
    .map(([domain, countryCode]) => [countryCode, domain])
)
