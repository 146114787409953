// this file can be used both server-side and client-side

import { countryCodeToDomain } from "@/lib/domains"
import { DEBUG, PREVIEW_DEPLOYMENT_SUFFIX, ROOT_DOMAIN } from "@/lib/env"


/**
 * Special case for preview deployment URLs; the hostname returned
 * is the routable hostname (the previous domain is dynamic, so it's generally not routable)
 */
export function maybePreviewHostname(hostname: string) {
  if (!(hostname.includes("---") && hostname.endsWith(`.${PREVIEW_DEPLOYMENT_SUFFIX}`))) {
    return hostname
  }

  hostname = `${hostname.split("---")[0]}.${ROOT_DOMAIN}`
  if (DEBUG) {
    console.log(`[Middleware] Adjusted preview deployment hostname: ${hostname}`)
  }

  return hostname
}

/**
 * Rewrite according to the following rules:
 * - se.localhost:3000 -> getminimist.se
 * - {tld}.localhost:3000 -> minimist.{tld}
 * 
 * Does NOT redirect to e.g. `se.mnm.st`, but uses the marketplace domains.
 */
export function maybeDevMarketplaceHostname(hostname: string) {
  // special case for local development; mimick mnm.st
  if (hostname === "localhost:3000") return "mnm.st"

  // not a marketplace request
  if (!/\w{2,3}[.]localhost:3000/g.test(hostname)) return hostname

  const tld = hostname.split(".")[0] // pick the leftmost subdomain
  const domain = countryCodeToDomain[tld]

  if (!domain) {
    console.info(`[middleware] No marketplace domain found for tld='${tld}', skipping rewrite`)
    return hostname
  }

  if (DEBUG) {
    console.log(`[middleware] [marketplace] Is marketplace root request for tld='${tld}', rewriting to '${domain}'`)
  }

  return domain
}
