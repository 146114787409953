import { cache } from 'react'

// See https://github.com/vercel/next.js/discussions/58862
function getCacheImpl() {
  const value: { domain?: string } = { domain: undefined }
  return value
}

const getCache = cache(getCacheImpl)

export function getRequestDomain() {
  return getCache().domain
}

export function setRequestDomain(domain: string) {
  getCache().domain = domain
}
