import { maybeDevMarketplaceHostname, maybePreviewHostname } from "@/lib/domain-rewrites"
import { BACKEND_URL, DEBUG, PUBLISHABLE_KEYS } from "@/lib/env"
import Medusa, { Client, ClientHeaders, Config, Store } from '@medusajs/js-sdk'
import { HttpTypes, StoreProduct, StoreProductCategoryListParams } from '@medusajs/types'
import { ProspectContactRequestCreateParams, ProspectJobApplicationCreateParams, StoreProfile, StoreWithProfile } from "./types"

type StoreProductListParams = HttpTypes.StoreProductListParams & {
  filter_reserved?: boolean
}

type StoreProductRetrieveResponse = HttpTypes.StoreProductResponse & {
  product: StoreProduct & {
    store?: StoreWithProfile
  }
}

class ExtendedStore extends Store {
  private _client: Client

  constructor(client: Client) {
    super(client)
    this._client = client

    this.cart = {
      ...this.cart,

      // Override deleteLineItem to use query params
      deleteLineItem: async (
        cartId: string,
        lineItemId: string,
        query?: HttpTypes.SelectParams,
        headers?: ClientHeaders
      ) => {
        return this._client.fetch<HttpTypes.StoreLineItemDeleteResponse>(
          `/store/carts/${cartId}/line-items/${lineItemId}`,
          {
            query,
            method: "DELETE",
            headers,
          }
        )
      },
    }
  }

  public cart_payment = {
    listPaymentProviders: async (query?: { cart_id?: string }, headers?: ClientHeaders) => {
      return this._client.fetch<HttpTypes.StorePaymentProviderListResponse>(`/store/payment-providers`, {
        query,
        headers
      })
    },

    refreshPaymentCollection: async (
      cart: HttpTypes.StoreCart,
      headers?: ClientHeaders
    ) => {
      const collectionBody = {
        cart_id: cart.id,
      }
      await this._client.fetch<HttpTypes.StorePaymentCollectionResponse>(
        `/store/payment-collections`,
        {
          method: "POST",
          headers,
          body: collectionBody,
        }
      )
    }
  }
}

type StoreProfilesListParams = {
  limit?: number
  offset?: number
  fields?: string | string[]
  store_id?: string | string[]
  status?: "draft" | "published" | "archived"
}

type StoreProfilesListResponse = {
  profiles: StoreProfile[]
  count: number
  offset: number
  limit: number
}

class Stores {
  constructor(protected client: Client) { }


  public products = {
    list: async (query?: StoreProductListParams, headers?: ClientHeaders): Promise<HttpTypes.StoreProductListResponse> => {
      return this.client.fetch(`/stores/products`, {
        query,
        headers
      })
    },

    listAll: async function* (query: Omit<StoreProductListParams, "limit" | "offset"> = {}, headers?: ClientHeaders) {
      let offset = 0
      while (true) {
        const results = await this.list({
          ...query,
          offset,
          limit: 100,
        }, headers)

        if (results.products.length === 0) break

        yield* results.products
        offset += 100
      }
    },

    retrieve: async (id: string, query?: HttpTypes.SelectParams, headers?: ClientHeaders): Promise<StoreProductRetrieveResponse> => {
      return this.client.fetch(`/stores/products/${encodeURIComponent(id)}`, {
        query,
        headers
      })
    },

    listRecommendations: async (id: string, query?: StoreProductListParams, headers?: ClientHeaders): Promise<HttpTypes.StoreProductListResponse> => {
      return this.client.fetch(`/stores/products/recommendations/${encodeURIComponent(id)}`, {
        query,
        headers
      })
    }
  }

  public profiles = {
    list: async (query: StoreProfilesListParams = {}): Promise<StoreProfilesListResponse> => {
      const { fields, ...rest } = query
      return this.client.fetch<StoreProfilesListResponse>(`/stores/profiles`, {
        query: {
          ...rest,
          fields: Array.isArray(fields) ? fields.join(',') : fields
        }
      })
    },

    listAll: async function* (query: Omit<StoreProfilesListParams, "limit" | "offset"> = {}) {
      let offset = 0
      while (true) {
        const results = await this.list({
          ...query,
          offset,
          limit: 100,
          fields: ["id", "handle"],
        })

        if (results.profiles.length === 0) break

        yield* results.profiles
        offset += 100
      }
    },

    retrieveByHandle: async (handle: string) => {
      try {
        const res = await this.client.fetch<StoreProfile>(`/store/profile/${encodeURIComponent(handle)}`)
        if (res.store == null) throw new Error(`Missing 'res.store' property!`)
        return res
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status === 404) {
          return null
        }
        throw error
      }
    }
  }

  public categories = {
    list: async (query: StoreProductListParams = {}, headers?: ClientHeaders): Promise<HttpTypes.StoreProductCategoryListResponse> => {
      return this.client.fetch<HttpTypes.StoreProductCategoryListResponse>(`/store/product-categories`, {
        query,
        headers
      })
    },

    listAll: async function* (query: Omit<StoreProductCategoryListParams, "limit" | "offset"> = {}, headers?: ClientHeaders) {
      let offset = 0
      while (true) {
        const results = await this.list({
          ...query,
          offset,
          limit: 100,
        }, headers)

        if (results.product_categories.length === 0) break

        yield* results.product_categories
        offset += 100
      }
    }
  }
}

class Prospects {
  constructor(protected client: Client) { }

  public contactRequests = {
    create: async (data: ProspectContactRequestCreateParams) => {
      return this.client.fetch(`/prospecting/contact`, {
        method: "POST",
        body: data,
      })
    }
  }

  public jobApplications = {
    create: async (data: ProspectJobApplicationCreateParams) => {
      return this.client.fetch(`/prospecting/job-application`, {
        method: "POST",
        body: data,
      })
    }
  }
}

export class ExtendedMedusa extends Medusa {

  public store: ExtendedStore

  public stores: Stores

  public prospects: Prospects

  constructor(config: Config) {
    super(config)

    this.store = new ExtendedStore(this.client)
    this.stores = new Stores(this.client)
    this.prospects = new Prospects(this.client)
  }
}

const clientCache = new Map<string, ExtendedMedusa>()

export const getClientForHostname = (hostname: string): ExtendedMedusa => {
  if (!hostname) throw new Error("No hostname provided")

  hostname = maybePreviewHostname(hostname)
  hostname = maybeDevMarketplaceHostname(hostname)

  const publishableKey = PUBLISHABLE_KEYS[hostname as keyof typeof PUBLISHABLE_KEYS]
  if (!publishableKey) {
    throw new Error(`Domain not configured in PUBLISHABLE_KEYS (mnm-st/src/lib/env.ts): '${hostname}'`)
  }

  const existing = clientCache.get(hostname)
  if (existing) {
    return existing
  }

  const client = new ExtendedMedusa({
    baseUrl: BACKEND_URL,
    debug: DEBUG,
    publishableKey,
  })

  clientCache.set(hostname, client)

  return client
}