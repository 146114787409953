/**
 * As this file is reused in several other files, try to keep it lean and small.
 * Importing other npm packages here could lead to needlessly increasing the client bundle size, or end up in a server-only function that don't need it.
 */

/**
 * PUBLIC: Minimist environment
 */
export const MINIMIST_ENV: 'prod' | 'dev' | 'test' = (process.env.NEXT_PUBLIC_MINIMIST_ENV || 'prod') as 'prod' | 'dev' | 'test'

/**
 * Typesense search only API key
 */
export const TYPESENSE_SEARCH_ONLY_API_KEY = process.env.TYPESENSE_SEARCH_ONLY_API_KEY || 'qorEvEuMmrlT3uhduVbsnbeJl6VPLfJ0'

/**
 * Typesense host
 */
export const TYPESENSE_HOST = process.env.TYPESENSE_HOST || 'esvcdu8rzkpbfgy4p.a1.typesense.net'

/**
 * Typesense port
 */
export const TYPESENSE_PORT = process.env.TYPESENSE_PORT || '443'

/**
 * Typesense protocol
 */
export const TYPESENSE_PROTOCOL = process.env.TYPESENSE_PROTOCOL || 'https'


/**
 * Sanity API read token
 */
export const SANITY_API_READ_TOKEN = process.env.SANITY_API_READ_TOKEN ?? process.env.NEXT_PUBLIC_SANITY_API_READ_TOKEN

/**
 * Sanity dataset
 */
export const SANITY_DATASET = process.env.NEXT_PUBLIC_SANITY_DATASET || 'production'

/**
 * Sanity project ID
 */
export const SANITY_PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'coak1kyy'

/**
 * see https://www.sanity.io/docs/api-versioning for how versioning works
 */
export const SANITY_API_VERSION = process.env.NEXT_PUBLIC_SANITY_API_VERSION || "2024-02-28"

/**
 * PUBLIC: Used to configure edit intent links, for Presentation Mode, as well as to configure where the Studio is mounted in the router.
 * 
 * Value similar to '/studio'
 */
export const SANITY_STUDIO_PATHNAME = process.env.NEXT_PUBLIC_SANITY_STUDIO_PATHNAME || "/studio"

/**
 * PUBLIC: URL to the Studio, used to configure edit intent links, for Presentation Mode.
 * 
 * Value similar to 'http://localhost:3000/studio'
 */
export const SANITY_STUDIO_URL = process.env.NEXT_PUBLIC_SANITY_STUDIO_URL || `http://localhost:3000${SANITY_STUDIO_PATHNAME}`

/**
 * PUBLIC: Use live API
 */
export const SANITY_USE_LIVE_API = process.env.NEXT_PUBLIC_SANITY_USE_LIVE_API === 'true'


/**
 * PUBLIC: Marketplace API endpoint
 */
export const BACKEND_URL = process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL || 'http://localhost:9000'

/**
 * PUBLIC: Root domain
 */
export const ROOT_DOMAIN = process.env.NEXT_PUBLIC_ROOT_DOMAIN || 'mnm.st'

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || `https://${ROOT_DOMAIN}`

/**
 * PUBLIC: Debug mode
 */
export const DEBUG = process.env.NEXT_PUBLIC_DEBUG === 'true'

/**
 * PUBLIC: Show careers page
 */
export const SHOW_CAREERS_PAGE = process.env.NEXT_PUBLIC_SHOW_CAREERS_PAGE === 'true'

/**
 * PUBLIC: Temporary B2B landing page
 */
export const SHOW_TEMPORARY_B2B_LANDING_PAGE = process.env.NEXT_PUBLIC_SHOW_TEMPORARY_B2B_LANDING_PAGE === 'true'

/**
 * PUBLIC: API publishable API key
 */
export const PUBLISHABLE_KEYS = {
  // -- used for demos:
  [ROOT_DOMAIN]: process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_ST || 'pk_bd834677d6521dbfcb270941d8370066662b1a411cec114d17e05cefdfb061f3',
  // -- live:
  "minimist.at": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_AT || 'pk_82a95cca1cd55ff809d4da130a620ab1ddb7e58cc9e353c78e087bab89bed5de',
  "getminimist.se": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_SE || 'pk_115d28b792418818a0cc00b8c961fa03fb9a7fe3a5624e08d7e7c80d8e3bb03d',
  "minimist.de": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_DE || 'pk_65a84a9992ca31237705b9da7dc13c973eb902abcca9a6c601fb6a1f21cd151f',
  "minimist.nl": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_NL || 'pk_a53ad6f85c50aa77e20ff75fd1f3ffb3fb8a63308bb8b618710e7ee387dedb96',
  // -- later:
  "minimist.fr": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_FR || 'pk_f5a9f9607bf23cce5047414f2c2e78a60334d2571281bb378c7ff5ab1823f243',
  "minimist.it": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_IT || 'pk_5c0455a7c70e3a3ee76a5a5b0a9fd3da35bd4b40162a23a75d0fa33b1e8f3996',
  "minimist.es": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_ES || 'pk_ec5eb678ed1727b1b13f4db37a69eae0ea0d0eef952c137aad56ed000537d143',
  "minimist.be": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_BE || 'pk_abdd4b0ceb65dfe04d7106ceb7f64220c33ec317c59f7ceb2ad7890bafdff628',
  "minimist.pt": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_PT || 'pk_d2bec506c6bc378c70216edc5c7cbc90622ffd9911ebee6efedf049286526b14',
  "minimist.fi": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_FI || 'pk_66d18ccebf14dba78eb8387ffc15fa2ebefe225e763b37669e5a374e38a1a726',
  "minimist.dk": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_DK || 'pk_cd10f7f7127e2ed9dec65c4fe96110528e84b32732e5a4b62de0d9174e9f372e',
  "minimist.pl": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_PL || 'pk_18e70ea9fdcbef0862a6fc11705285a53199709820e327f237e8f7d0b33518c4',
  "minimist.cz": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_CZ || 'pk_55066ed4b674abbee2be11715846efdeb055f25847253c2402fce8cdb9de882d',
  "minimist.hu": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_HU || 'pk_0fd3360d3c3920f6b1f1724d51442d4665375291b2a42ca1e2ad896668d51cb0',
  "minimist.gr": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_GR || 'pk_4db11ca65d7c6d6f67a42c9032c323a29009ef027062b3315e5a5ac0542cb978',
  "minimist.ie": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_IE || 'pk_481bfce83bbc59cd62271b06a0e5b6909e9b9f5809d3a61c36dad7d9b745d960',
  "minimist.hr": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_HR || 'pk_82cb807efaa9b8be20c089b7c7495ca9ddcdf8450a1ffe0fd936786ba7c3d088',
  "minimist.ro": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_RO || 'pk_cbc2235b8ce2f6a6ba7bbc7c53398c88d69d45f895d4ddfa3499124bfaff7d61',
  "minimist.bg": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_BG || 'pk_4ef627ed41a23d44d89f41a3b96d3e7a9307ba71e3b29c399c20c90ac15aa87c',
  "minimist.cy": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_CY || 'pk_59ada956cbaa27546183691369c2b8684ca7c1549e55148b490bd76847b345fc',
  "minimist.mt": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_MT || 'pk_fed53049a268f62f914c1ea04f8be1c6cbd17d60819e69cb31ac82f356882106',
  "minimist.lv": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_LV || 'pk_3ff9715ef6049569dbb7a1188896115c609916219ade84a922045a38b062e4c5',
  "minimist.lt": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_LT || 'pk_9b1c108775e5fbee5c8bdf16254f43c129b374620e795dc1b2f05b359ee1efb6',
  "minimist.ee": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_EE || 'pk_9990e9d9433dfd7dd2a0ef40bdfcec3d0312662f0b665544cdc54ccca05c37e5',
  "minimist.sk": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_SK || 'pk_782d72f398bf9068cd62e1b9392d67fc9f35f83de8281529487a6b4af526afd6',
  "minimist.si": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_SI || 'pk_28cd571323ed5ae56e20696ac72b1c55ff9e1a3989d647dfb1c42044a1b56510',
  "minimist.lu": process.env.NEXT_PUBLIC_PUBLISHABLE_KEY_LU || 'pk_20ab971b9f093f0f4d71c3e275289ff4671c9f09619d521509e5a2fbe9235e49',
}


/**
 * PUBLIC: suffix for preview deployments
 */
export const PREVIEW_DEPLOYMENT_SUFFIX = process.env.NEXT_PUBLIC_PREVIEW_DEPLOYMENT_SUFFIX


/**
 * PUBLIC: Stripe publishable key
 */
export const STRIPE_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || 'pk_test_51PBHcdKgoZRiYH20yJWLq7mu0rMpSNT2hpvFrLuFYiDLYNRKv0xcQFBOVhbWfwODPMe9hRV6vNLlOnSBx1vAl2Oc00puv9N4XN'

/**
 * PUBLIC: Unsplash access key
 */
export const UNSPLASH_ACCESS_KEY = process.env.NEXT_PUBLIC_UNSPLASH_ACCESS_KEY || 'spC1YiBFZHM6btDmHs7BARXrAflIeiC-Oxxg3uzlnBM'

/**
 * PUBLIC: Unsplash API URL
 */
export const UNSPLASH_API_URL = 'https://api.unsplash.com'

/**
 * Google Maps API key
 */
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || 'AIzaSyDMC8U2iFRzggSiAFyeyorY9JYFvmJq564'
