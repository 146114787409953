import { StoreRegion } from "@medusajs/types"
import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const formatMap = {
  "at": "de-AT",
  "se": "sv-SE",
}

export function formatPrice(price: number, region: StoreRegion) {
  const currencyCode = region.currency_code
  const countryCode = region.countries?.[0].iso_2

  return Intl.NumberFormat(formatMap[countryCode as keyof typeof formatMap] || countryCode,
    { style: 'currency', currency: currencyCode }).format(
      price,
    )
}
