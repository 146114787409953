'use client'

import { getClientForHostname } from "."
import { getRequestDomain } from "../[domain]/request-domain-cache"

export const getHostnameClientSide = (hostname?: string) => {
  console.log(`[API] getHostnameClientSide called with ${hostname ?? 'no hostname'}, window=${typeof window}`)

  if (hostname) return hostname

  /**
   * This is to prevent build errors. 
   * 
   * Note that `window` is undefined in the server, so we should use the `getDomainServerSide` function instead.
   */
  if (typeof window === 'undefined') {
    const cached = getRequestDomain()
    if (cached) return cached
    // TODO: how to handle this??
    return 'se.localhost:3000'
  }

  return window.location.port ? `${window.location.hostname}:${window.location.port}` : window.location.hostname
}

export const getClientAPI = (hostname?: string) => {
  hostname = hostname ?? getHostnameClientSide()
  return getClientForHostname(hostname)
}
